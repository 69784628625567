<template>
    <div
    class="transition-color ease-out duration-400 "
      :class="{
        'flex space-x-3': variant === 'horizontal',
      }"
    >
      <ul
        class="list-none p-1 rounded-lg text-center overflow-auto whitespace-nowrap "
        :class="{
          'flex items-center mb-6 gap-1': variant === 'vertical',
        }"
      >
        <li
          v-for="(tab, index) in tabList"
          :key="index"
          class="w-1/6 px-0 rounded-md text-sm md:text-lg"
          :class="{
            'text-white bg-slate-800': index + 1 === activeTab,
            'transition ease-in-out text-black hover:bg-orange-100 ': index + 1 !== activeTab,
          }"
          style="user-select: none;"
          >
          <label
          :for="`${_uid}${index}`"
          v-text="tab"
          class="py-3 cursor-pointer block"
          @click="$emit('updateIndex', index)"
          />
          <input
          :id="`${_uid}${index}`"
          type="radio"
          :name="`${_uid}-tab`"
          :value="index + 1"
          v-model="activeTab"
          class="hidden"
          />
        </li>
      </ul>
      <template v-for="(tab, index) in tabList">
        <div
          :key="index"
          v-if="index + 1 === activeTab"
          class="pl-1"
        >
          <slot :name="`tabPanel-${index + 1}`" />
        </div>
      </template>
    </div>
  </template>

  <script>
  export default {
    props: {
      tabList: {
        type: Array,
        required: true,
      },
      variant: {
        type: String,
        required: false,
        default: () => "vertical",
        validator: (value) => ["horizontal", "vertical"].includes(value),
      },
    },
    data() {
      return {
        activeTab: 1,
      };
    },
  };
</script>
