<!-- Master layout design -->
<template>
<div class="container px-4 pt-3 md:mx-auto md:px-36 xl:px-72 transition ease-in duration-600 ">
    <div class="mb-4">
        <Title :arr-index="reactiveCount"></Title>
    </div>
    <div>
        <app-tabs :tabList="tabList" @update-index="updateIndex">
            <template v-slot:tabPanel-1></template>
            <template v-slot:tabPanel-2></template>
            <template v-slot:tabPanel-3></template>
        </app-tabs>
        <RouterView></RouterView>
    </div>
</div>
</template>

<script setup lang="ts">
//@ts-ignore
import Title from "./components/Title.vue";
//@ts-ignore
import Recipe from "./components/Recipe.vue";
import AppTabs from "./components/AppTabs.vue";
import { ref } from "vue";
import Ruby from "./components/Ruby.vue";
import { router } from "./router";

const tabList = ["Vue", "Ruby", "Go"];

const reactiveCount = ref(0);

// Vue how to make a reactive variable
// https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/#1-check-that-variables-are-reactive

function updateIndex(n: number) {
    reactiveCount.value = n;
    router.push({ path: `/${tabList[n].toLowerCase()}`})
}

</script>

<style lang="scss">
</style>
