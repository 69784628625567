<template>
<div class="titles mx-2">
    <div :class="color">Neo's</div>
    <div :class="color">Website</div>
    <div :class="color">Recipe</div>
</div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
const props = defineProps<{ arrIndex: number }>()

const color = computed(() => {
  const dv = "font-extrabold transition-color ease-in-out duration-700 ";
  switch (props.arrIndex) {
    case 0:
      return dv + "text-green-500";
    case 1:
      return dv + "text-red-600";
    case 2:
      return dv + "text-cyan-500";
    default:
      return "None";
  }
})

</script>

<style scoped lang="scss">
.titles {
    @apply transition ease-in-out text-black font-sans font-semibold text-7xl sm:text-7xl xl:text-8xl;
    user-select: none;
}

#website {
    @apply text-green-400;
}

#development {
    @apply text-green-800;
}

#recipe{
    @apply text-green-900;
}

h1 {
  font-family: "Roboto Flex";
  font-variation-settings: "wght" 1000, "wdth" 151, "opsz" 13.44, "GRAD" -0.5, "slnt" 0, "XTRA" 468.6, "XOPQ" 96.56, "YOPQ" 78.9, "YTLC" 514.56, "YTUC" 711.28, "YTAS" 749.45, "YTDE" -230.48, "YTFI" 737.84;
}
</style>
