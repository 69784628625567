import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: '/', redirect: '/vue' },
    { path: '/vue', component: () => import("./components/Recipe.vue") },
    { path: '/ruby', component: () => import("./components/Ruby.vue") },
    { path: '/go', component: () => import("./components/Go.vue") },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export { router }
