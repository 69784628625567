import { createApp } from "vue";
import Vue from "vue";
import { router } from "./router";
//@ts-ignore
import App from "./App.vue";

import 'highlight.js/styles/github.css'
import hljs from "highlight.js"; 'highlight.js/lib/core';
import shell from 'highlight.js/lib/languages/shell';
import json from 'highlight.js/lib/languages/json';
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage('shell', shell);
hljs.registerLanguage('json', json);
import './index.scss'

const app = createApp(App);
app.use(hljsVuePlugin);
app.use(router);
app.mount("#app");
